<template>
  <NuxtLink
    class="base-link"
    :to="to || href"
    :class="[computedClass, size]"
    v-bind="$attrs"
  >
    <span class="relative z-10"><slot /></span>
  </NuxtLink>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useThemeVariant } from '~/composables';
import type { BtnSizes, BtnVariants } from '~/types';

const { btnClassMap } = useThemeVariant();

interface Props {
  as?: 'a' | 'NuxtLink'
  href?: string // if is anchor tag <a href...
  to?: string // if is NuxtLink tag
  variant?: BtnVariants
  size?: BtnSizes
}
const props = withDefaults(defineProps<Props>(), {
  as: 'NuxtLink',
  variant: 'primary',
  size: 'base',
  href: undefined,
  to: undefined,
});

const computedClass = computed(() => {
  return btnClassMap(props.variant as BtnVariants);
});
</script>

<style scoped>
.base-link {
  @apply inline-flex items-center justify-center tracking-wide rounded-md font-semibold outline-2 outline-offset-2 transition-all duration-300 cursor-pointer flex-shrink-0 relative overflow-hidden active:translate-y-0.5 hover:delay-0 delay-150;
  &.base {
    @apply py-2.5 px-6 text-sm;
  }
  &.xl {
    @apply py-5 px-10 text-base;
  }
}
</style>
